body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.mainWindows {
    margin: 0;
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: flex-start;
    border-top: 1px solid #9e9e9e;
    width: 100%;
    height: 100%;
}
.mainActivity {
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    display: flex;
    position: absolute;
}
.CADViewer {
    width: 60%;
    border-right: 1px solid #9e9e9e;
}
.progectViewer {
    width: 60%;
    border-right: 1px solid #9e9e9e;       
}
.mainActivityBackGround {
    background-color: #f5f5f5;
}
.authPageLogo {
    width: 400px;
    height: 300px;
    background: url('../static/images/factoryLogo.svg');
    background-repeat: no-repeat;
}

.authPageLogoContainer {
   display: flex;
   flex-direction: column;
}

.authPageInputContainer {
    display: flex;
    flex-direction: column;
 }

.panelAuth {
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    position: absolute;
    width: 100%;
}

.authPageContainer{
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
 }

.cardHead {
    margin: 15px;
    width: 224px;
    min-height: 126px;
    border-radius: 2px;
}
.padContentHead {
    width: 224px;
    min-height: 126px;
    border-radius: 2px;
    flex-direction: row;
}
.colorPadOneCard {
    background: #009688;
}
.colorPadTwoCard {
    background: #CCA11D;
}
.colorPadThreeCard {
    background: #B85151;
}
.leftOneContentCardHead {
    width: 162px;
    border-right: 1px solid #ffffff;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}
.leftTwoContentCardHead {
    width: 224px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}
.rightTopContentCardHead {
    width: 62px;
    height: 63px;
    border-bottom: 1px solid #ffffff;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
}
.rightBottpmContentCardHead {
    height: 63px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
}
.leftBottomCardHeadDate {
    flex-direction: row;
    justify-content: space-between;
}
.leftOneBottomCardHead {
    width: 140px;
    flex-direction: column;
}
.startContentCardHead {
    flex-direction: column;
    width: 140px;
    align-items: flex-start;
}
.leftTwoBottomCardHead {
    width: 200px;
    flex-direction: column;
}
.expansionPanelCuttings {
    width: 39.1vw;
}
.inputEditCuttings {
    width: 3vw;
}
.inputEditNumbSurfaces {
    width: 2vw;
}
.textCuTtingAndSurface {
    width: 120px;
}
.buttomContentCardHead {
    margin: 30px;
    background: linear-gradient(0deg, #009688 100%, #000000 0%);
}
.buttomCardHead {
    margin: 30px;
}
.panelEmptyDescription {
    height: 100vh;
    width: 39.9vw;
    justify-content: center;
    flex-direction: column;
}
.neadPanelDescriptionEmpty {
    margin-top: 25px;
    height: 100vh;
    width: 39.9vw;
    flex-direction: column;
}
.panelDescription {
    height: 85vh;
    width: 39.9vw;
    overflow-y: scroll;
}
.HeadRightTextPanelHeader {
    margin-left: 15px;
}
.headerPanelRight {
    margin: 15px;
    flex-direction: column;
}
.headerPanelContent {
    margin: 25px;
    margin-top: 13px;
    margin-bottom: 13px;
}
.headContentRight {
    flex-direction: column;
}
.headPanelContentRight {
    flex-direction: row;
    width: 28vw;
    flex-wrap: wrap;
}
.headTitleContentRight {
    width: 15vw;
    margin: 15px;
    margin-left: 25px;
    margin-top: 0px;
}
.headValueContentRight {
    margin-left: 15px;
}
.headExpansionPanelDescription {
    width: 39.0vw;
}
.headContentPanelDescription {
    flex-direction: column;
}
.headTableCellContent {
    flex-direction: row;
    align-items: center;
}
.headTableRowContent {
    flex-wrap: wrap;
}
.headTableContent {
    overflow-x: auto; 
    margin-bottom: 15px;
}
.headListImagesCardContenr {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.colorPick {
    width: 27px;
    height: 17px;
    border-radius: 2px;
    border: 1px solid #888888;
    margin: 10px;
}
.headCardGridTitle {
    font-size: 8;
}
.headTableCellBottom {
    flex-direction: column;
    width: 45px;
}
.mainWindowEditor{
    width: 100%;
    height: 88vh;
    border-bottom: 1px solid #9e9e9e;
    flex-direction: row;
}
.buttonEditorViewer {
    width: 15vw;
}
.editorViewer {
    width: 15vw;
    border-right: 1px solid #9e9e9e;
}
.filterDocsRoot {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
    padding-right: 6px;
    padding-left: 6px;
}
.expansionPanelSurfaces {
    width: 39.1vw;
}
.headerPanelSurfaces {
    margin-right: 15px;
}
.inputEditSurfaces {
    width: 5vw;
}
.inputEditNumbSurfaces {
    width: 2vw;
}
.columnPanelMaterial {
    flex-basis: 100.00%;
}
.moldingCard {
    margin-bottom: 8px;
    margin-top: 8px;
    width: 37vw;
}
.moldingIdItem {
    width: 15px;
}
.moldingTitleItem {
    width: 160px;
}
.moldingSpaceItem {
    width: 45px;
}
.moldingSurfaceItem {
    width: 130px;
}
.moldingContent {
    overflow-y: scroll;
    width: 39.9vw;
    height: 69.6vh;
}
.listSurfaceNav {
    flex-wrap: wrap; 
    flex-direction: row; 
    justify-content: center; 
    align-items: center;
}

.lanes {
	display: inline-block;
}

.lane__item {
	display: inline-block;
	margin-right: -4px;
}

.lane__item + .lane__item:before {
	content: '➞';
	color: black;
}


.colorPickContainer{
    display: flex;
    flex: 4;  
    flex-direction: row;
    align-items: center;
}

.projectContainer {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.projectScreen{
    display: flex;
    flex-direction: column;
    padding: 12px;
    padding-bottom: 80px;
    width: 100%;
    height: 100%;
}

.subProjectContainer{
    padding: 12px;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow: auto;
}

.subProjectCardInfo{
    display: flex;
    flex-direction: row;
    margin-top: 6px;
}

.projectContainerHead{
    display: flex;
    flex-direction: row;
    
    width: 100%;
    border-bottom: 1px solid gray;
}

.subProjectContainerHead{
    display: flex;
    flex-direction: row;
    padding: 12px;
    padding-left: 24px;

    width: 100%;
    border-bottom: 1px solid gray;   
}

.selectedProduct{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin: 12px;
}

.projectsTechDocs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
    height: 100%;
    flex: 2;
    border-right: 1px solid gray;
}

.projectsProductionSteps {
    display: flex;
    flex-direction: row;
    width: auto;
    height: 100%;
    flex-wrap: nowrap;
    flex: 6;
    overflow: auto;
}

.techDocCard {
    display: flex;
    flex-direction: column;
}

.techDocInfoContainer{
    display: flex;
    flex-direction: row;
}

.createNewProjectContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.createNewProjectRow{
    display: flex;
    flex-direction: row;
    align-items: center; 
    width: 100%;
}

.techDocInfoRow{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
}

.productInfoRow{
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: flex-start;
}

.productCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 12px;
    width: 100%;
}

.taskMiniDescriptionCard{
    display: flex;
    flex-direction: column;
}

.taskMiniDescriptionTime{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.taskDescriptionTopPanelContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 136px;
    /* min-width: 400px; */
}

.taskDescriptionTopPanelRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;  
    align-items: center;  
    margin-top: 12px;
}

.taskDescriptionTopPanelRowSelect{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;  
    align-items: center;  
    margin-top: 6px;    
}

.taskDescriptionFlex{
    display: flex;
    flex: 1;
    
}

.tasksContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 64px);
    overflow-y: hidden;
    overflow-x: hidden;
}

.taskConveyor{
    flex: 3;
    overflow: auto;
    padding: 12px;
    background-color: #f5f5f5;
}

.taskDescription{
    display: flex;
    flex-direction: column;
    flex: 2;
    /* width: 100%;
    height: 100%; */
    overflow-y: auto;
    padding: 0px 24px 24px 24px;
    background-color: #f5f5f5;
    border-left: 1px solid gray;
}

.attachmentsGridListTileBar{
    display: flex;
    flex-direction: row;
    margin-right: 8px;
}

.attachments{
    margin-bottom: 12px;
}

.attachmentsContainer{
    display: flex;
    flex-direction: column;
}

.authorizationTextField{
    width: 300px;
    justify-content: flex-end;

}

.coveringLayersInfoTemplate{
    display: flex;
    flex-direction: row;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    align-items: center;
}

.polymerizationDiagram{
    display: flex;
    flex-direction: column;
}

.noSelectedTask{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectedTask{
    /* width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; */
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    overflow-x: hidden;
}

.usersDialogInputRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.usersRoleRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 6px;
}

.usersAdding{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
}