body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.editorContentContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.parameterSelectionContainer {
	display: flex;
	flex-direction: row;
	margin-top: 8px;
	margin-bottom: 8px;
}

.descriptionContainer {
	margin-left: 18px;
	margin-right: 18px;
	margin-top: 12px;
	margin-bottom: 12px;
	width: 100%;
}

.assamblyStepContent {
	height: 100%;
	width: 100%;
	display: flex;
    overflow-y: scroll;
    overflow-x: hidden;
}

.navigationButtonsContainer {
	height: 62px;
	width: inherit;
	display: flex;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.5);
	bottom: 0;
	border-top: 2px solid #f2f2f2;
}
.navigationButtonsTwoButtons {
    flex-direction: row;
    display: flex;
}

.navigationButtonsFlex {
	flex-direction: row;
	display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.navigationButton {
	align-self: center;
	padding-left: 12px;
	padding-right: 12px;
}

.workingPlaceContainer {
	/* border-left: 1px solid #888888; */
	width: 40%;
    height: 100%;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
    z-index: 1;
}

gridListTileBarSubtitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.techDocCreatingPageRoot {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
}

.mainWindow {
    margin: 0;
    flex: 2;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    background-color: #ffffff;
    overflow-y: hidden;
    overflow-x: hidden;
}

.mainActivity {
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    display: flex;
}
.CADViewer {
    width: 60%;
}
.progectViewer{
    width: 60%;
    border-right: 1px solid #9e9e9e;
}
.mainActivityBackGround {
    background-color: #f5f5f5;
}

.cardHead {
    margin: 15px;
    width: 224px;
    min-height: 126px;
    border-radius: 2px;
}
.padContentHead {
    width: 224px;
    min-height: 126px;
    border-radius: 2px;
    flex-direction: row;
}
.colorPadOneCard {
    background: #009688;
}
.colorPadTwoCard {
    background: #CCA11D;
}
.colorPadThreeCard {
    background: #B85151;
}
.leftOneContentCardHead {
    width: 162px;
    border-right: 1px solid #ffffff;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}
.leftTwoContentCardHead {
    width: 224px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}
.rightTopContentCardHead {
    width: 62px;
    height: 63px;
    border-bottom: 1px solid #ffffff;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
}
.rightBottpmContentCardHead {
    height: 63px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
}
.leftBottomCardHeadDate {
    flex-direction: row;
    justify-content: space-between;
}
.leftOneBottomCardHead {
    width: 140px;
    flex-direction: column;
}
.startContentCardHead {
    flex-direction: column;
    width: 140px;
    align-items: flex-start;
}
.leftTwoBottomCardHead {
    width: 200px;
    flex-direction: column;
}
.expansionPanelCuttings {
    width: 39.1vw;
}
.inputEditCuttings {
    width: 3vw;
}
.inputEditNumbSurfaces {
    width: 2vw;
}
.textCuTtingAndSurface {
    width: 120px;
}
.buttomContentCardHead {
    margin: 30px;
    background: linear-gradient(0deg, #009688 100%, #000000 0%);
}
.buttomCardHead {
    margin: 30px;
}
.panelEmptyDescription {
    height: 100%;
    width: 40%;
    justify-content: center;
    flex-direction: column;
}
.neadPanelDescriptionEmpty {
    margin-top: 25px;
    height: 100%;
    width: 40%;
    flex-direction: column;
}
.panelDescription {
    height: 100%;
    width: 40%;
    overflow-y: scroll;
}
.HeadRightTextPanelHeader {
    margin-left: 15px;
}
.headerPanelRight {
    margin: 15px;
    flex-direction: column;
}
.headerPanelContent {
    margin: 25px;
    margin-top: 13px;
    margin-bottom: 13px;
}
.headContentRight {
    flex-direction: column;
}
.headPanelContentRight {
    flex-direction: row;
    width: 28vw;
    flex-wrap: wrap;
}
.headTitleContentRight {
    width: 15vw;
    margin: 15px;
    margin-left: 25px;
    margin-top: 0px;
}
.headValueContentRight {
    margin-left: 15px;
}
.headExpansionPanelDescription {
    width: 39.0vw;
}
.headContentPanelDescription {
    flex-direction: column;
}
.headTableCellContent {
    flex-direction: row;
    align-items: center;
}
.headTableRowContent {
    flex-wrap: wrap;
}
.headTableContent {
    margin-bottom: 15px;
}
.headListImagesCardContenr {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.colorPick {
    width: 27px;
    height: 17px;
    border-radius: 2px;
    border: 1px solid #888888;
    margin: 10px;
}
.dndColorPick {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    margin-left: 10px;
    
}
.headCardGridTitle {
    font-size: 8;
}
.headTableCellBottom {
    flex-direction: column;
    width: 45px;
}
.mainWindowEditor{
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #9e9e9e;
    flex-direction: row;
}
.editorViewer{
    width: 15%;
    border-right: 1px solid #9e9e9e;
}
.buttonEditorViewer {
    width: 15%;
}
.listSurfaceNav {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.fixedButton {
    right: 24px;
    bottom: 24px;
    position: fixed;
}

.headStepperLeftSide{
    flex: 5;
    margin-left: 12px;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    height: auto;
}

.centerStepper{
    flex: 16;
    width: 100%;
}

.stepperRightSide{
    flex: 3;
    margin-right: 12px;
    margin-top: 12px;
}

.justDisplayFlex{
    display: flex;
}

.totalCosts{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.center{
    align-self: center;
}

.totalCostsPriceTime{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.headTaskTemplate{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;   
    align-items: center;
}

.controlInfoTaskTemplate{
    display: block;
}

.techDocCreatingPageVNCDisplay{
    width: 60%;
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fullTaskDescriptionBottomPanel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #888888;
}

.layersFacingInfoTaskTemplates{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
}

.coveringLayersInfoTemplate{
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
    align-items: center;
}
.preparingLayersInfoTaskTemplates{
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    width: 100%;
    align-items: center;
}

.contentTaskTemplates{
    width:100%;
    display: block;
}

.taskTemplateList{
    width: 100%;
}

.setTimeDurationTaskTemplates{
    width: 100%;
    display:flex;
    flex-direction: row;
    padding-top: 12px;
}

.timeDurationInputTaskTemplate{
    flex: 1;
    flex-direction: row;
    display: flex;
}

.timeDurationAndPrice{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 2;
}

.polymerizationDiagram{
    display: flex;
    flex-direction: column;
}

.attachmentsContainer{
    display: flex;
    flex-direction: column;
    flex-grow: 1;

}

.attachmentsGridListTileBar{
    display: flex;
    flex-direction: row;
    margin-right: 8px;
}

.attachments{
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
}

.materialsList{
    width: 100%;
}

.materialsInfo{
    width: 100%;
    overflow-x: hidden;
}

.containerQCCriteria{
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 16px 0;
    width: auto;
    display: block;
    border-radius: 4px;
    background-color: #f3f3f4;
}

.graphicsEditor{
    height: 100%;
}

.partOfCriteriaList{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

}

.addCriterionButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
}

.selectCriterionType{
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    align-items: center;
}

.productsListScreen{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
}

.productsList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.productCard{
    padding: 12px;
    margin-bottom: 24px;
}

.colorPickContainer{
    display: flex;
    flex: 4;  
    flex-direction: row;
    align-items: center;
}

.colorPickContainerDnd{
    display: flex;
    flex: 3;  
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
}


.techDocsListContainer{
    display: flex;
    flex-direction: row;
    height: calc(100% - 64px);
    width: 100%;
    position: relative;
}

.techDocsColumn{
    display: flex;
    padding: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    flex: 2;
    border-right: 1px solid gray;
}

.filesColumn{
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding-top: 12px;
}

.uploadToServerButton{
    width: 90%;
    margin-top: 12px;
    margin-bottom: 12px;
}

.buttonContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.fileDescriptionRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.usersContainer{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.surfaceContainer{
    width: 100%;
}

.surfaceSettingSizeContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.textFieldContainer{
    margin-right: 12px;
}

.layersTableContainer{
    width: 100%;
}

.editorWorkingPlaceContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 64px);
}

.editorFunctionsContainer{
    width: 360px;
    display: flex;
    border-right: 3px solid #f3f3f4;
    flex-direction: column;
}

.editorBodyContainer{
    width: 100%;
    display: flex;
}

.editorFunctionsFlex{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.listContainer{
    width: 100%;
    height: calc(100% - 230px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.layerFunctionsContainer{
    height: auto;
    display: flex;
    justify-content: center;
}

.cutFunctionsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 102px;
}

.layerFunctions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 12px;
}

.surfaceAddingContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.freeCadCommandsContainer{
    height: 64px;
    width: 60%;
    /* border-top: 1px solid gray; */
    position: absolute;
    background-color: #f2f2f2;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

.measureTextContainer{
    width: 182px;
    display: flex;
    background-color: #ffffff;
    padding: 12px;
    border: 2px solid #e6e4e4;
}

.groupTagDescriptionContainer{
    margin-top: 12px;
    margin-bottom: 12px;
}

.vncDisplayContainder{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paramField{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 0;
}

.analyticsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 36px;
}